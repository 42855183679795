<template>
  <div v-if="loading">Loading...</div>
  <div v-else-if="error">Error: {{ error.message }}</div>
  <div v-else-if="resourcesMap" class="table-responsive">
    <table class="table table-sm small">
      <thead class="table-light">
        <tr>
          <th
            v-for="(column, index) in resourcesMap.columns"
            :key="index"
            class="fit"
          >
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody v-if="hasResources">
        <tr
          v-for="(row, index) in resourcesMap.data"
          :key="index"
          class="button st-table-hover"
          role="button"
          @click="linkTo(row.key)"
          @keypress="linkTo(row.key)"
        >
          <td v-for="(item, itemIndex) in row.values" :key="itemIndex">
            {{ item }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td>No resources</td>
        </tr>
      </tbody>
    </table>
  </div>

  <PaginationApi
    v-if="hasResources"
    :page-size="state.pageSize"
    :handle-page-size-change="handlePageSizeChange"
    :has-previous-page="hasPreviousPage"
    :has-next-page="hasNextPage"
    :load-more="loadMore"
  />
</template>

<script>
import { useQuery, useResult } from "@vue/apollo-composable";
import { reactive, computed } from "vue";
import { useRouter } from "vue-router";

import PaginationApi from "@/components/PaginationApi.vue";
import useLoadMore from "@/composables/useLoadMore";
import executionQuery from "@/graphql/execution.query.gql";
import firstExecutionQuery from "@/graphql/firstExecution.query.gql";
import { getFormatDateTime } from "@/utils/datetime.js";
import { resourcesColumnsMapping } from "@/utils/resourcesColumnsMapping.js";

export default {
  name: "ResourceTable",
  components: { PaginationApi },
  props: {
    resourceType: {
      type: String,
      default: null,
    },
    policyId: {
      type: String,
      default: null,
    },
    executionUUID: {
      type: String,
      default: null,
    },
    gqlQueryKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const state = reactive({
      pageSize: 10,
    });
    const RESOURCE_TABLE_IN_POLICY = "ResourceTable";
    const RESOURCE_TABLE_IN_EXECUTION = "resourceTableByExecution";

    const gqlQueryMap = {
      [RESOURCE_TABLE_IN_POLICY]: firstExecutionQuery,
      [RESOURCE_TABLE_IN_EXECUTION]: executionQuery,
    };
    const gqlQuery = gqlQueryMap[props.gqlQueryKey];
    const gqlVariables = {
      first: computed(() => state.pageSize),
      last: computed(() => state.pageSize),
      after: "",
      before: "",
    };
    if (props.gqlQueryKey === RESOURCE_TABLE_IN_POLICY) {
      gqlVariables.policyUUID = computed(() => props.policyId);
    } else if (props.gqlQueryKey === RESOURCE_TABLE_IN_EXECUTION) {
      gqlVariables.uuid = computed(() => props.executionUUID);
    }

    const { result, loading, error, fetchMore } = useQuery(
      gqlQuery,
      gqlVariables,
      { notifyOnNetworkStatusChange: true }
    );

    const resourcesMap = useResult(result, {}, (data) => {
      let source;
      if (props.gqlQueryKey === RESOURCE_TABLE_IN_POLICY) {
        source = data.executions.edges[0].node.resources;
      } else if (props.gqlQueryKey === RESOURCE_TABLE_IN_EXECUTION) {
        source = data.execution.resources;
      }
      const resources = source.edges.map((edge) => edge.node);
      const rawData = resources.map((resource) => {
        return {
          resourceData: JSON.parse(resource.data),
          key: resource.key,
        };
      });
      const resourceType = resourcesColumnsMapping[props.resourceType]
        ? props.resourceType
        : "default";
      return {
        columns: resourcesColumnsMapping[resourceType].columns.map(
          (column) => column.name
        ),
        data: rawData.map((data) => {
          const dataObject = {
            key: data.key,
            values: [],
          };
          resourcesColumnsMapping[resourceType].columns.forEach((column) => {
            let value;
            //TODO: quick fix for demo - make this recursive
            if (column.attribute.includes(".")) {
              const attArray = column.attribute.split(".");
              value = data.resourceData[attArray[0]][attArray[1]] || "--";
            } else {
              value = data.resourceData[column.attribute] || "--";
            }
            //For rest-stage resource, if there's a value for webAclArn then return the value Yes, else return No
            if (column.attribute === "webAclArn") {
              value = data.resourceData[column.attribute] ? "Yes" : "No";
            }
            if (Array.isArray(value)) {
              value = value.toString();
            }
            if (column.type === "time") {
              value = data.resourceData[column.attribute]
                ? getFormatDateTime(data.resourceData[column.attribute])
                : "--";
            }
            return dataObject.values.push(value);
          });

          return dataObject;
        }),
      };
    });

    const hasResources = computed(() => resourcesMap.value.data?.length > 0);

    const pageInfo = useResult(
      result,
      { hasPreviousPage: false, hasNextPage: false },
      (data) => {
        if (props.gqlQueryKey === RESOURCE_TABLE_IN_POLICY) {
          return data.executions.edges[0].node.resources.pageInfo;
        } else if (props.gqlQueryKey === RESOURCE_TABLE_IN_EXECUTION) {
          return data.execution.resources.pageInfo;
        }
      }
    );

    const { hasPreviousPage, hasNextPage, loadMore } = useLoadMore(
      fetchMore,
      pageInfo
    );

    const handlePageSizeChange = (pageSize) => {
      if (pageSize) {
        state.pageSize = pageSize;
      }
    };

    const linkTo = (resourceKey) => {
      router.push({
        name: "ResourceDetail",
        params: { resourceKey },
      });
    };

    return {
      state,
      loading,
      error,
      hasPreviousPage,
      hasNextPage,
      loadMore,
      handlePageSizeChange,
      linkTo,
      resourcesMap,
      resourcesColumnsMapping,
      hasResources,
    };
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1800px) {
  .fit {
    white-space: nowrap;
    width: 1%;
  }
}
</style>
